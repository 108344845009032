<template>
    <div class="content">
        <div>
            <md-field>
                <md-input-item
                    type="text"
                    is-highlight
                    title="姓名"
                    v-model="formData.name"
                    placeholder="输入姓名"
                    :error="formRule.name.error? formRule.name.msg:''"
                    @blur="checkReg('name')"
                />
                <md-input-item
                    is-highlight
                    type="text"
                    title="职位"
                    v-model="formData.zhiwei"
                    placeholder="职位名称"
                    :error="formRule.zhiwei.error? formRule.zhiwei.msg:''"
                    @blur="checkReg('zhiwei')"
                />
                <md-input-item
                    is-highlight
                    type="text"
                    title="职称"
                    v-model="formData.zhicheng"
                    placeholder="所属职称"
                    :error="formRule.zhicheng.error? formRule.zhicheng.msg:''"
                    @blur="checkReg('zhicheng')"
                />
                <md-input-item
                    is-highlight
                    type="text"
                    title="邮箱"
                    v-model="formData.user_email"
                    placeholder="例:10172232@qq.com"
                    :error="formRule.user_email.error? formRule.user_email.msg:''"
                    @blur="checkReg('user_email')"
                />
                <md-input-item
                    is-highlight
                    type="phone"
                    title="手机号码"
                    v-model="formData.mobile"
                    placeholder="常用手机号"
                    :error="formRule.mobile.error? formRule.mobile.msg:''"
                    @blur="checkReg('mobile')"
                />
                <md-input-item
                    is-highlight
                    type="text"
                    title="微信号"
                    v-model="formData.wechat"
                    placeholder="例:xxxxA21"
                    :error="formRule.wechat.error? formRule.wechat.msg:''"
                    @blur="checkReg('wechat')"
                />
                <md-input-item
                    is-highlight
                    type="text"
                    title="身份证号"
                    v-model="formData.idcard"
                    placeholder="18位身份号"
                    :error="formRule.idcard.error? formRule.idcard.msg:''"
                    @blur="checkReg('idcard')"
                />
            </md-field>
            <div class="submit-btn">
                <md-button type="primary" @click="submit">提交表单</md-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {InputItem, Field, Toast} from 'mand-mobile'

    export default {
        name: "InviteForm",
        components: {
            [InputItem.name]: InputItem,
            [Field.name]: Field,
        },
        data() {
            return {
                collage_id: 37201, // 学校id
                formData: {
                    name: '',
                    zhiwei: '',
                    zhicheng: '',
                    user_email: '',
                    mobile: '',
                    wechat: '',
                    idcard: ''
                },
                formRule: {
                    name: {
                        error: false,
                        msg: '姓名不能为空',
                        reg: new RegExp()
                    },
                    zhiwei: {
                        error: false,
                        msg: '职位不能为空',
                        reg: new RegExp()
                    },
                    zhicheng: {
                        error: false,
                        msg: '职称不能为空',
                        reg: new RegExp()
                    },
                    user_email: {
                        error: false,
                        msg: '请填写正确的邮箱',
                        reg: new RegExp(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/)
                    },
                    mobile: {
                        error: false,
                        msg: '请填写正确的手机号',
                        reg: new RegExp(/^1\d{10}$/)
                    },
                    wechat: {
                        error: false,
                        msg: '请输入正确格式的微信号',
                        reg: new RegExp()
                    },
                    idcard: {
                        error: false,
                        msg: '请填写正确的身份证号码',
                        reg: new RegExp(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)
                    }
                }
            }
        },
        methods: {
            checkReg(name) {
                let isError = !this.formRule[name].reg.test(this.formData[name]) || this.formData[name] == ''
                this.formRule[name].error = isError
                return isError
            },
            submit() {
                for (let i in this.formData) {
                    if(this.checkReg(i)) {
                        return false
                    }
                }

                /** 验证成功，提交表单 */
                this.$API.register.userregister(this.$route.query.collage_id, this.formData).then(res => {
                    if (res.errno == 0) {
                        Toast.succeed('提交成功')
                    } else {
                        Toast.failed(res.msg)
                    }


                }).catch(() => {

                })


            }
        }
    }
</script>

<style scoped>

.submit-btn {
    padding: 0 8%;
    margin-top:70px;
}
</style>
